
import mobile from './mobile.js'

const loadVideo = (target) => {
    target.src = target.dataset.src

}

const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
        const video = entry.target
        if (entry.isIntersecting) {
            if (!video.src) {
                loadVideo(video)
            } else {
                if(video.paused){
                    video.currentTime = 0;
                    video.play()
                }
                
            }

        } else {
            if(!video.paused){
                video.pause()
            }
            
        }
        //observer.unobserve(entry.target);

    });
}, {
    rootMargin: '0px 0px 200px 0px'
});

var init = function () {
    if (mobile.isMobile) return
    const videos = document.querySelectorAll('video')
    videos.forEach(video => observer.observe(video));
}

export default {
    init
}