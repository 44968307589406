import textFit from 'textfit'
import debounce from 'lodash/debounce'
const textFitConf = { widthOnly: true, maxFontSize: 300 }
let fittedTexts

const init = () => {
  fittedTexts = document.querySelectorAll('.project h2')
  observeText(fittedTexts)
  const fitText = () => textFit(fittedTexts, textFitConf)
  'fonts' in document ? document.fonts.ready.then(fitText) : window.onload = () => fitText
  window.addEventListener('resize', debounce(fitText, 100))
}

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
      const headline = entry.target
      if (!entry.isIntersecting) return
      textFit(headline, textFitConf)
      console.log(headline);
      observer.unobserve(entry.target);

  });
}, {
  rootMargin: '0px 0px 300px 0px'
});

var observeText = function (headlines) {
  headlines.forEach(headlines => observer.observe(headlines));
}


export default {
  init
}
